<template>
    <div>
        <HeroBasic
            v-if="heroProgramsProjects?.mobileHeroImage?.url"
            :heading="title"
            :heroImageUrl="heroProgramsProjects.mobileHeroImage.url"
            :eyebrow="$t('Programs')"
            :isTall="true"
            :hasHeaderOffset="true"
            class="mobile-hero"
        />
        <HeroBasic
            v-if="heroProgramsProjects?.heroImage?.url"
            :heading="title"
            :heroImageUrl="heroProgramsProjects.heroImage.url"
            :eyebrow="$t('Programs')"
            :isTall="true"
            :hasHeaderOffset="true"
            class="desktop-hero"
        />
        <SubnavBlock
            :navigationLinks="subNav?.navigationLinks"
            :socialNetworks="subNav?.socialNetworks"
            :button="subNav?.button"
        />
        <Tier background="grey" top-padding="standard" bottom-padding="standard">
            <div class="about-section">
                <div class="about-type">
                    <div>
                        <Typography variant="h4-display">{{ $t('About the Program', 1, {
                            locale: pageLanguage,
                        }) }}</Typography>
                        <RichTextElements :elements="about" bodyStyle="serif-small" class="dek" />
                    </div>
                    <ContentCardBlock
                        class="featured-card"
                        v-if="projects?.length !== 0"
                        :related="featuredContentItems?.[0]"
                        size="50"
                        orientation="wide"
                        :hideDescription="true"
                    />
                </div>
                <ContentCardBlock
                    v-if="projects?.length === 0"
                    :related="featuredContentItems?.[0]"
                    size="33"
                    :hideDescription="true"
                    class="pinned-work"
                >
                    <template #icon>
                        <Icon name="pin" class="pin" />
                    </template>
                </ContentCardBlock>
                <AffiliationCard
                    v-else-if="projects?.length === 1"
                    :eyebrow="$t('Projects')"
                    :heading="projects[0]?.value?.title"
                    :dek="projects[0]?.value?.dek"
                    :url="projects[0]?.value?.path"
                    headingElement="h3"
                    class="card"
                />
                <div v-else class="multiple-projects">
                    <Typography variant="label" as="h2" transform="uppercase">{{ $t('Projects', 1, {
                            locale: pageLanguage,
                        }) }}</Typography>
                    <ul>
                        <li>
                            <Anchor v-for="project in projects" :key="project?.value?.id" :url="project?.value?.path" class="anchor">
                                <Typography variant="body-display-large" as="div" class="anchor-title">{{
                                    project?.value?.title
                                }}</Typography>
                                <AvatarSet :people="project?.value?.contributors" hideNames />
                            </Anchor>
                        </li>
                    </ul>
                </div>
            </div>
        </Tier>
        <Tier
            background="darkblue"
            top-padding="extra"
            bottom-padding="extra"
            :eyebrow="$t('Program experts', 1, {
                            locale: pageLanguage,
                        })"
            :centerEyebrow="true"
            v-if="displayExperts && nonArchivedContributors?.length"
        >
            <PersonFeedBlock :people="nonArchivedContributors" hideDescriptions size="100" theme="dark" />
        </Tier>
        <Tier
            background="grey"
            top-padding="standard"
            bottom-padding="standard"
            :eyebrow="$t('Latest work', 1, {
                            locale: pageLanguage,
                        })"
            v-if="latestWork?.feed?.length || latestWork?.dynamic?.length"
        >
            <ContentFeedBlock size="100" :contentFeed="latestWork" />
        </Tier>
        <StreamTierFeed v-if="streamTiered" :streamTiered="streamTiered" />
        <template v-if="id && pageLanguage">
            <IndexTier class="index" :title="`${$t('All work from', 1, { locale: pageLanguage })} ${title}`" :contentType="contentType" :pageId="id">
                <template #results="{ results }" v-if="results">
                    <ContentCardBlock
                        v-for="result in results"
                        :key="result.id"
                        :related="result"
                        orientation="wide"
                        size="100"
                        :hideDescription="true"
                        :isProportionate="true"
                        class="result"
                        :hideDateCard="true"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </template>
            </IndexTier>
        </template>
    </div>
</template>
<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
    },
    about: {
        type: Object,
    },
    contributors: {
        type: Array,
        default: () => [],
    },
    displayExperts: {
        type: Boolean,
    },
    heroProgramsProjects: {
        type: Object,
    },
    id: {
        type: String,
    },
    contentType: {
        type: String,
        default: '',
    },
    subNav: {
        type: Object,
    },
    latestWork: {
        type: Object,
    },
    projects: {
        type: Array,
        default: () => [],
    },
    featuredContent: {
        type: Object,
    },
    streamTiered: {
        type: Object,
    },
    socialNetworks: {
        type: Array,
        default: () => [],
    },
});

const nonArchivedContributors = computed(() => {
    return props.contributors?.filter((data) => !data.isArchived);
});

const featuredContentItems = computed(() =>
    getContentFeedItems(props.featuredContent?.feed, props.featuredContent?.dynamic),
);

const headerAttributesStore = useHeaderAttributesStore();
const GTMTitle = props.title;
onMounted(() => {
    window.dataLayer.push({
        Center: 'CEIP',
        Doctype: 'Program Page',
        Expert: '',
        'Taxonomy Tags Program': GTMTitle,
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': 'en',
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
</script>
<style lang="scss" scoped>
.dek {
    opacity: 0.8;
}

.featured-card {
    margin-top: 3.5rem;
}

.about-section {
    @include grid($layout: '50');
    & {
        @include content-area-padding;
    }
}

.pin {
    opacity: 0.5;
}

.multiple-projects {
    max-width: 75%;
    margin: 0 auto;
}

.anchor {
    margin-top: 1.2rem;
    display: flex;
    min-height: 3.6rem;
    width: 100%;
    justify-content: space-between;
    gap: 1.2rem;

    border-bottom: 1px solid palette(border-light);

    .anchor-title {
        color: color(blue, default, 0.75);
    }
}

// so we can have person lists that match the experts lists, we are hiding person details on a person feed on program pages
:deep(.personFeedBlock .content-wrap .person-details) {
    display: none;
}
</style>
